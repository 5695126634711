import React from 'react'
import Header from '../Header/Header'
import "./Hero.css"
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {
    const transition={type: 'spring',duration: 3}
    const mobile=window.innerWidth<=768 ? true: false
  return (
    <div className='hero' id='home'>
        <div className="blur hero-blur"></div>
        <div className='left-h'>
            <Header/>
             {/* the best ad */}
            <div className="the-best-ad">
                <motion.div
                initial={{left:mobile?'165': '270px'}}
                whileInView={{left: '5px'}}
                transition={{...transition, type: 'tween'}}
                ></motion.div> 
                <span>The Bset Fitness Club in the Bangladesh</span>
            </div>
            {/* Hero Heading */}
            <div className='hero-text'>
                <div>
                <span className='stroke-text'>Build </span>
                <span>Your</span></div>
            <div><span>Perfect  Body</span></div>
            <div >
                <span>
                    In here we will help you to shape and build your ideal body and live up your life to fullest

                </span>
            
                </div>     
         </div>
         {/* figures */}
         <div className="figures">
            <div>
               <span>
                <NumberCounter end={150} start={100} delay={4} preFix='+'/>
               </span>
               <span>Expertize</span>
            </div>

            <div>
                <span><NumberCounter end={1000} start={850} delay={4} preFix='+'/></span>
                <span>Students</span>
            </div>
            <div>
                <span><NumberCounter end={50} start={0} delay={4} /></span>
                <span>Fitness Programs</span>
            </div></div>
            {/* hero Button */}
            <div className="hero-buttons">

            <buttons className="btn  ">Get Started</buttons>
            <buttons className="btn">Learn More</buttons>
            
         </div>
        </div>
        <div className='right-h'>
            <button className='btn'>Join Now</button>


            <motion.div
            initial={{right:'-1rem'}}
            whileInView={{right:'4rem'}}
            transition={transition} 
            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span><span>116 bpm</span>
            </motion.div>
            {/* hero  images */}
        <img src={hero_image} alt='' className='hero-image'/>
         <motion.img 
         initial={{right:'11rem'}}
         whileInView={{right:'20rem'}}
         transition={transition}
         src={hero_image_back} alt='' className='hero-image-back'/>
         {/* calories */}
         <motion.div
         initial={{right:'37rem'}}
         whileInView={{right:'28rem'}}
         transition={transition}
         className="calories">
            <img src={calories} alt=''/>
             <span>Calories Burned</span>
             <span>220 Kcal</span>
         </motion.div>


            </div>
        </div>
  )
}

export default Hero
